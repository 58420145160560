import axios, {AxiosRequestConfig, AxiosResponse, Method} from "axios"
interface CallAxiosAPIProps{
    url: string
    method: Method
    data?: any
    headers?: any
    params?: string
    isAuthentication?: boolean
    responseType?: any
}

const baseURL = `${process.env.REACT_APP_API_URL ?? "https://ipn-tms-dev.qrsoundboxnepal.com/api/v1/"}`

export const callAxios = async ({url, method, data, headers, params} : CallAxiosAPIProps) => {
    // const token = await sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken");
    // const accessToken = token !== null ? token : null
    const config : AxiosRequestConfig = {
        method: method || "GET",
        url: `${baseURL}${url}`,
        headers: {
            'Content-Type' : 'application/json',
            // Authorization: accessToken !== null ? `Bearer ${accessToken}` : '',
            ...headers
        },
        data,
        params,
        timeout: 30000,
    }
    return axios(config).then((res: AxiosResponse<any, any>)=> res).catch((error:any) => error)
}
