import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../common/components/toast";
import { callAxios } from "../../../plugins/axios";
import BasicLoader from "../../common/components/BasicLoader";
import BasicDialog from "../../common/components/BasicDialog";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BasicCopyright from "../../common/components/BasicCopyright";
import { validationError } from "../../../utils/ValidationError";
import axios from "axios";

//interfaces
interface IFormData {
  serial_number: string;
  language: string;
}

const SetLanguage = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [formData, setFormData] = React.useState<IFormData>({
    serial_number: "",
    language: "",
  });
  const [languages, setLanguages] = React.useState<any[]>([]);
  const [loader, setLoader] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<any>({});
  const handleCloseDialog = () => {
    setOpenModal(false);
    setErrors({});
  };

  //onchange input handler
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //final dialog action handler for modal
  const onClickDialogActionHandler = async () => {
    setLoader(true);
    try {
      const res = await callAxios({
        method: "POST",
        url: "user/set-password",
        data: formData,
      });

      //response handling
      if (res?.status === 200) {
        toast(res?.data?.message, successToast);
        navigate("/");
      } else if (res?.response?.status === 400) {
        validationError(res);
      } else {
        toast(res?.response?.statusText, errorToast);
      }
    } catch (error) {
      toast("Something went wrong", errorToast);
    }
    setLoader(false);
  };

  //modal
  const confirmDialog = (
    <>
      <div className="p-2 grid grid-col-1 gap-4 w-96 overflow-x-hidden">
        Are you sure you want to continue this action?
      </div>
    </>
  );

  const dialogAction = (
    <>
      <Button variant="outlined" color="error" onClick={handleCloseDialog}>
        No
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onClickDialogActionHandler()}
      >
        Yes
      </Button>
    </>
  );

  //form validation with proper error messages
  const validateForm = () => {
    const regexPattern = /^\d{16}$/;
    const newErrors: any = {};

    if (!formData?.serial_number?.trim()) {
      newErrors.serial_number = "Serial Number is required";
    }

    if (
      formData?.serial_number?.trim() &&
      !regexPattern.test(formData?.serial_number)
    ) {
      newErrors.serial_number = "Serial Number is invalid";
    }

    if (!formData?.language?.trim()) {
      newErrors.language = "Language is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //onsubmit form handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setOpenModal(true);
    }
  };

  const loadLanguageList = async () => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_KOILI_CORE}`)
      .then((res: any) => {
        setLanguages(res?.data?.languages || []);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoader(false));
  };

  React.useEffect(() => {
    loadLanguageList();
  }, []);

  return (
    <div className="w-full">
      {loader ? (
        <BasicLoader />
      ) : (
        <Container component="main" maxWidth="sm">
          <BasicDialog
            open={openModal}
            onClose={handleCloseDialog}
            title={"Set Language"}
            content={confirmDialog}
            actions={dialogAction}
          />
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="flex flex-col justify-center items-center w-full gap-y-8">
              <div className="w-2/3 mr-10">
                <img src="/logo.svg" alt="koili_logo" />
              </div>
              <div className="flex flex-col w-full items-center justify-center">
                <Box
                  component="form"
                  className="w-full"
                  onSubmit={handleSubmit}
                  sx={{ mb: 1 }}
                >
                  <TextField
                    margin="normal"
                    fullWidth
                    placeholder="XXXXXXXXXXXXXXX"
                    id="serial_number"
                    label="Serial Number"
                    type="number"
                    name="serial_number"
                    autoComplete="serial_number"
                    autoFocus
                    onChange={handleInputChange}
                    error={!!errors.serial_number}
                    helperText={errors.serial_number}
                  />
                  <div className="mt-2">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        <span
                          className={`${errors.language ? "text-red-500" : ""}`}
                        >
                          Language
                        </span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Language"
                        name="language"
                        onChange={handleInputChange}
                        value={formData?.language ?? ""}
                        error={!!errors.language}
                      >
                        {languages?.map((data) => (
                          <MenuItem value={data?.id}>{data?.name}</MenuItem>
                        ))}
                      </Select>
                      {errors.language && (
                        <Typography
                          variant="caption"
                          color="error"
                          className="pl-4"
                        >
                          {errors.language}
                        </Typography>
                      )}
                    </FormControl>
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Confirm IPN Language
                  </Button>
                </Box>
                <BasicCopyright sx={{ mt: 8, mb: 4 }} />
              </div>
            </div>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default SetLanguage;
